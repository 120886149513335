import React, { useState, useEffect } from "react";
import { Typography, List, Row, Col } from "antd";
import CustomModal from "./CustomModal";
import {
  DownOutlined,
  UpOutlined,
  DeleteFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import apiCall from "../../services/apiCall";
import moment from "moment";
import ToastNotification from "../../helpers/ToastNotification";

const RemoveWorkorderModal = ({
  visible,
  onCancel,
  selectedRowKeys,
  clientList,
  clearSelection,
}) => {
  const { Text } = Typography;
  const [expandedWorkOrders, setExpandedWorkOrders] = useState({});
  const [loading, setLoading] = useState({ isLoading: false, deletedWo: "" });
  const [success, setSuccess] = useState(false);

  const [workOrders, setWorkOrders] = useState(() =>
    Array.from(
      new Set(
        clientList
          ?.filter((clientObj) => selectedRowKeys?.includes(clientObj._id))
          .flatMap((clientObj) =>
            clientObj.workOrders.map((wo) => wo.workOrderNo)
          )
      )
    )
  );

  useEffect(() => {
    if (!workOrders.length) {
      onCancel("remove", true);
      setExpandedWorkOrders({});
    }
  }, [workOrders, onCancel]);

  const handleDeleteWorkorder = async (WOtoDelete) => {
    try {
      if (loading.isLoading) return;

      setLoading({ isLoading: true, deletedWo: WOtoDelete });

      const proctors = selectedRowKeys.map((id) => ({
        id,
      }));

      const payLoad = {
        proctors,
        WOtoDelete,
      };

      const data = await apiCall.deleteBulkWorkorders(payLoad);
      if (data?.status === 200) {
        ToastNotification("success", data.data);
        setWorkOrders((prev) => prev.filter((wo) => wo !== WOtoDelete));
        setExpandedWorkOrders((prev) => {
          const updated = { ...prev };
          delete updated[WOtoDelete];
          return updated;
        });

        setSuccess(true);
      } else {
        ToastNotification("warning", data?.data);
        // setSuccess(false);
      }

      setLoading({ isLoading: false, deletedWo: "" });
    } catch (error) {
      setLoading({ isLoading: false, deletedWo: "" });
      ToastNotification("error", "Something went wrong");
    }
  };

  const handleGetWorkorderDetails = async (workOrder) => {
    if (expandedWorkOrders[workOrder]?.data) {
      setExpandedWorkOrders((prev) => ({
        ...prev,
        [workOrder]: {
          ...prev[workOrder],
          expanded: !prev[workOrder].expanded,
        },
      }));
      return;
    }

    setExpandedWorkOrders((prev) => ({
      ...prev,
      [workOrder]: {
        expanded: true,
      },
    }));

    try {
      const data = await apiCall.getWorkorderDetails(workOrder);
      // Cache the data and keep the work order expanded
      setExpandedWorkOrders((prev) => ({
        ...prev,
        [workOrder]: {
          expanded: true,
          data: data.data,
        },
      }));
    } catch (error) {
      setExpandedWorkOrders((prev) => ({
        ...prev,
        [workOrder]: {
          expanded: false,
        },
      }));
    }
  };

  const stringToDate = (date) => {
    if (date) {
      return moment(date, "YYYYMMDD").format("MMM DD, YYYY");
    }
    return date;
  };

  return (
    <CustomModal
      visible={visible}
      onCancel={() => onCancel("remove", success)}
      title="Remove Workorders"
    >
      <div className="remove-wo-container">
        <p className="sub-heading">You can remove the selected workorders</p>
        <List
          dataSource={workOrders}
          renderItem={(item) => (
            <List.Item className="remove-wo-list-item">
              <div className="remove-wo-list-item-container">
                <div className="remove-wo-list-item-inner">
                  <div>
                    <Typography.Text className="wo-title">
                      Workorder: <span className="wo-no">{item}</span>
                    </Typography.Text>
                  </div>
                  {!expandedWorkOrders[item]?.expanded ? (
                    <DownOutlined
                      onClick={() => handleGetWorkorderDetails(item)}
                      className="down-arrow"
                    />
                  ) : (
                    <UpOutlined
                      onClick={() => handleGetWorkorderDetails(item)}
                      className="up-arrow"
                    />
                  )}
                </div>

                {/* workorder details */}
                {expandedWorkOrders[item]?.expanded && (
                  <div>
                    {expandedWorkOrders[item]?.data ? (
                      <>
                        <Row className="workorder-details-container">
                          <Col span={8} className="workorder-details">
                            <Text type="secondary">Organization</Text>
                            <Text>
                              {expandedWorkOrders[item].data.clientName}
                            </Text>
                          </Col>
                          <Col span={8} className="workorder-details">
                            <Text type="secondary">Facility</Text>
                            <Text>
                              {expandedWorkOrders[item].data.facilityName}
                            </Text>
                          </Col>
                          <Col span={8} className="workorder-details">
                            <Text type="secondary">Start Date</Text>
                            <Text>
                              {stringToDate(
                                expandedWorkOrders[item].data.startDate
                              )}
                            </Text>
                          </Col>
                        </Row>
                        <Row className="workorder-details-container">
                          <Col span={8} className="workorder-details">
                            <Text type="secondary">End Date</Text>
                            <Text>
                              {stringToDate(
                                expandedWorkOrders[item].data.endDate
                              )}
                            </Text>
                          </Col>
                          <Col span={16} className="workorder-details">
                            <Text type="secondary">Address</Text>
                            <Text>
                              {expandedWorkOrders[item].data.woAddressLine1}
                            </Text>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Text>
                        <LoadingOutlined />
                      </Text>
                    )}
                  </div>
                )}
              </div>
              {loading.isLoading && loading.deletedWo === item ? (
                <LoadingOutlined className="remove-icon" />
              ) : (
                <DeleteFilled
                  onClick={() => handleDeleteWorkorder(item)}
                  className="remove-icon"
                />
              )}
            </List.Item>
          )}
          className="removed-wo-listing"
        />
      </div>
    </CustomModal>
  );
};

export default RemoveWorkorderModal;
