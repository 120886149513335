import React, { useState, useEffect, useCallback } from "react";
import { Select, Spin, Skeleton, Divider } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as EmailIcon } from "../../assets/icon_mail.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icon_phone.svg";
import ToastNotification from "../../helpers/ToastNotification";
import ContentTable from "../../components/ContentTable";
import ListItems from "../../components/ListItems";
import PageHead from "../../components/PageHead";
// import "../../styles/exam-admin.css";
import apiCall from "../../services/apiCall";
import useViewPort from "../../services/responsiveHelper";
import { useNavigate, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import _debounce from "lodash/debounce";

const ExamineticsAdmin = () => {
  const history = useNavigate();
  const location = useLocation();
  const [adminList, setAdminList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState(
    location.state?.adminStatus ?? "all"
  );
  const [adminSearchText, setAdminSearchText] = useState(
    location.state?.adminSearchText?.trim()
  );
  const customPageSize = 10;

  const { width } = useViewPort();
  const breakpoint = 670;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 36,
        color: "rgb(0, 48, 135)",
        fontWeight: "bold",
      }}
      spin
    />
  );

  const columns = [
    {
      title: "Admin Name",
      dataIndex: ["firstName", "lastName"],
      key: "firstName",
      width: "23%",
      render: (_text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "30%",

      render: (email) => (
        <span className="table-cell-container">
          <div className="table-cell-icon">
            <EmailIcon />
          </div>
          {email}
        </span>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "23%",

      render: (phone) => (
        <span className="table-cell-container">
          {phone ? (
            <>
              <div className="table-cell-icon">
                <PhoneIcon />
              </div>
              {phone}
            </>
          ) : (
            <div>Not Set</div>
          )}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "24%",

      render: (status) => {
        let color;
        if (status === "Active") color = "rgb( 98, 181, 229)";
        else if (status === "InActive") color = "rgb( 154,154,154)";
        else color = "rgb( 232,119,34)";

        return (
          <span>
            <span className="status" style={{ backgroundColor: color }} />
            <span className="status-text">{status ? status : "Pending"}</span>
          </span>
        );
      },
    },
  ];

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  function handleDebounceFn(searchValue, filterValue) {
    populateAdminList(0, customPageSize, searchValue, filterValue);
    history("/examinetics-admin", {
      state: { ...location.state, adminSearchText: searchValue },
      replace: true,
    });
  }
  const handleSearchChange = (searchValue) => {
    setAdminSearchText(searchValue);
    debounceFn(searchValue, filterValue);
  };
  const pageHeaderProps = {
    btnAdd: "Add",
    onBtnClick: () => {
      history({
        pathname: "/examinetics-admin-form",
        state: location.state,
      });
    },
  };
  const userTypesDropdown = (
    <span className="select">
      <Select
        className="user-type-dropdown-source"
        defaultValue="all"
        value={filterValue}
        onSelect={(value) => {
          setFilterValue(value);
          history('/examinetics-admin', { 
            state: { ...location.state, adminStatus: value },
            replace: true 
          });
        }}
      >
        <Select.Option value="all" key={1}>
          All Users
        </Select.Option>
        <Select.Option value="Active" key={2}>
          Active Users
        </Select.Option>
        <Select.Option value="InActive" key={3}>
          InActive Users
        </Select.Option>
        <Select.Option value="Pending" key={4}>
          Pending Users
        </Select.Option>
      </Select>
    </span>
  );
  /** API Calls */
  const populateAdminList = async (page, size, searchValue, filterValue) => {
    setLoading(true);
    setLoadingList(true);

    const filter = {
      ...(filterValue && filterValue !== "all" && { status: filterValue }),
      searchValue,
    };
    const data = await apiCall.getAdminUsers(page, size, filter);

    if (data?.status === 200) {
      setCurrentPage(data?.data?.page);
      setAdminList(data?.data?.docs);
      setTotalCount(data?.data?.totalDocs);
      setLoading(false);
      setLoadingList(false);
    } else {
      ToastNotification("error", "Error fetching User records.");
    }
    setLoading(false);
    setLoadingList(false);
  };

  const loadMoreData = () => {
    if (loadingList) {
      return;
    }

    populateAdminList(currentPage, 20, adminSearchText, filterValue);
  };
  useEffect(() => {
    if (width > breakpoint) {
      populateAdminList(0, customPageSize, adminSearchText, filterValue);
    } else {
      loadMoreData();
    }
  }, [filterValue]);
  useEffect(() => () => {
    if (history.action === "POP") {
      history(1);
    }
  });
  useEffect(() => {
    if (width < breakpoint) {
      loadMoreData();
    }
  }, []);
  const showLoader = loading ? (
    <Spin indicator={antIcon} className="loader" />
  ) : (
    <ContentTable
      data={adminList}
      columns={columns}
      onClick={(recordIndex) => ({
        onClick: () => {
          history(`/examinetics-admin-form/${recordIndex._id}`, {
            state: { ...location.state, id: recordIndex._id },
          });
        },
      })}
      pagination={{
        pageSize: customPageSize,
        total: totalCount,
        current: currentPage,
        onChange: async (page, pageSize) => {
          setCurrentPage(page);
          await populateAdminList(
            page - 1,
            pageSize,
            adminSearchText,
            filterValue
          );
        },
      }}
    />
  );
  return (
    <div>
      <div className="main-container">
        <PageHead
          title={"Examinetics Admins"}
          searchPlaceholderTxt="Search"
          handleSearchChange={handleSearchChange}
          searchValue={adminSearchText}
          dropDown={userTypesDropdown}
          {...pageHeaderProps}
          width={width}
          breakpoint={breakpoint}
          key={"ExamineticsAdmin"}
          customStyles
        >
          {width > breakpoint ? (
            showLoader
          ) : (
            <div
              id="scrollableDiv"
              className="infinite-scroll-container"
            >
              <InfiniteScroll
                dataLength={totalCount}
                next={loadMoreData}
                hasMore={totalCount < 100}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more </Divider>}
                scrollableTarget="scrollableDiv"
                className="infinite-scroll"
              >
                <ListItems
                  data={adminList}
                  key={"ExamineticsAdmin" + Math.random()}
                />
              </InfiniteScroll>
            </div>
          )}
        </PageHead>
      </div>
      ;
    </div>
  );
};

export default ExamineticsAdmin;
