import React from "react";
import {
  Layout,
  Button,
  Input,
  Divider,
  Switch,
  Space,
  Typography,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import { ReactComponent as BackIcon } from "../assets/icon_back.svg";
import { ReactComponent as AddIcon } from "../assets/icon_add.svg";
import { ReactComponent as ReplaceIcon } from "../assets/icon_replace.svg";
import { ReactComponent as RemoveIcon } from "../assets/icon_remove.svg";
import CustomPageHeader from "./CustomPageHeader";

const { Header } = Layout;

const { Search } = Input;

const PageHead = (props) => {
  const {
    children,
    title,
    subTitle,
    backBtnPath,
    btnType,
    searchPlaceholderTxt,
    dropDown,
    btnAdd,
    onBtnClick,
    btnUpdate,
    toggleStatus,
    onChangeSwitch,
    defaultChecked,
    switchDisabled,
    divider,
    routeState,
    formName,
    onBtnEdit,
    toggleClassname,
    searchValue,
    width,
    breakpoint,
    handleSearchChange,
    customStyles,
    actionButtons,
    onAddBtnClick,
    onReplaceBtnClick,
    onRemoveBtnClick,
    WOSelectCount,
    enableSelection,
    showCheckboxes,
    clearSelection,
    handleClearFilters,
  } = props;

  const history = useNavigate();
  const setButtonType = btnType ? btnType : "primary";
  const setFormName = formName ? formName : null;
  const { Text } = Typography;

  const innerOverlayStyle = {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px",
    borderRadius: "12px",
  };
  const extraTablePageHeaderContent =
    width > breakpoint
      ? [
          actionButtons && (
            <Space className="action-button-group">
              <span className="bulk-selection-switch">
                <Switch
                  onClick={enableSelection}
                  checked={showCheckboxes}
                  className={`${
                    showCheckboxes
                      ? "bulk-selection-enabled"
                      : "bulk-selection-disabled"
                  } bulk-switch-button`}
                  key="bulk-selection-switch"
                />
                Bulk Operation
              </span>
              <Text> </Text>
              <Tooltip
                overlayInnerStyle={innerOverlayStyle}
                title="Add Workorder"
                placement="bottom"
              >
                <Button
                  shape="circle"
                  icon={
                    <AddIcon
                      className={
                        showCheckboxes ? "action-add" : "action-disabled "
                      }
                    />
                  }
                  className={`${
                    showCheckboxes
                      ? "action-button add"
                      : "action-button-disabled"
                  }`}
                  onClick={() => {
                    if (!WOSelectCount) return;
                    onAddBtnClick();
                  }}
                  disabled={!showCheckboxes}
                />
              </Tooltip>
              <Tooltip
                overlayInnerStyle={innerOverlayStyle}
                title="Replace Workorder"
                placement="bottom"
              >
                <Button
                  shape="circle"
                  icon={
                    <ReplaceIcon
                      className={
                        showCheckboxes ? "action-replace" : "action-disabled "
                      }
                    />
                  }
                  className={`${
                    showCheckboxes
                      ? "action-button swap"
                      : "action-button-disabled"
                  }`}
                  onClick={() => {
                    if (!WOSelectCount) return;
                    onReplaceBtnClick();
                  }}
                  disabled={!showCheckboxes}
                />
              </Tooltip>
              <Tooltip
                overlayInnerStyle={innerOverlayStyle}
                title="Remove Workorder"
                placement="bottom"
              >
                <Button
                  shape="circle"
                  icon={
                    <RemoveIcon
                      className={
                        showCheckboxes ? "action-remove" : "action-disabled "
                      }
                    />
                  }
                  className={`${
                    showCheckboxes
                      ? "action-button delete"
                      : "action-button-disabled"
                  }`}
                  onClick={() => {
                    if (!WOSelectCount) return;
                    onRemoveBtnClick();
                  }}
                  disabled={!showCheckboxes}
                />
              </Tooltip>
            </Space>
          ),
          searchPlaceholderTxt ? (
            <Search
              // autoFocus
              placeholder={searchPlaceholderTxt}
              value={searchValue}
              onChange={(e) => handleSearchChange(e.target.value)}
              allowClear
              key="searchInput"
              className="search-bar"
            />
          ) : null,
          // clearFilters ? (
          //   <Button onClick={clearFilters}>Clear Filters</Button>
          // ) : null,
          dropDown ? dropDown : null,
          toggleStatus ? (
            <span className="switch-status">
              <Switch
                onClick={onChangeSwitch}
                disabled={switchDisabled}
                checked={defaultChecked}
                key="switchStatus"
                className={
                  defaultChecked
                    ? switchDisabled
                      ? "switch-button-form-disabled"
                      : toggleClassname
                    : toggleClassname
                }
              />
              {toggleStatus}
            </span>
          ) : null,
          btnAdd ? (
            <Button
              type={setButtonType}
              onClick={onBtnClick}
              key="actionBtn1"
              shape="round"
              form={setFormName}
            >
              {btnAdd}
            </Button>
          ) : null,
          btnUpdate ? (
            <Button
              type="secondary"
              htmlType="button"
              onClick={onBtnEdit}
              key="clearActionBtn"
              shape="round"
              className="delete-btn-none"
              style={{
                marginRight: "0px",
              }}
              form={setFormName}
            >
              {btnUpdate}
            </Button>
          ) : null,
        ]
      : [
          toggleStatus ? (
            <span className="switch-status">
              <Switch
                onClick={onChangeSwitch}
                disabled={switchDisabled}
                checked={defaultChecked}
                key="switchStatus"
                className={
                  defaultChecked
                    ? switchDisabled
                      ? "switch-button-form-disabled"
                      : toggleClassname
                    : toggleClassname
                }
              />
              {toggleStatus}
            </span>
          ) : null,
          btnAdd ? (
            <Button
              type={setButtonType}
              onClick={onBtnClick}
              key="actionBtn"
              shape="round"
              form={setFormName}
            >
              {btnAdd}
            </Button>
          ) : null,
          searchPlaceholderTxt ? (
            <Search
              // autoFocus
              placeholder={searchPlaceholderTxt}
              value={searchValue}
              onChange={(e) => handleSearchChange(e.target.value)}
              allowClear
              key="searchInput"
              className="search-bar"
            />
          ) : null,
          dropDown ? dropDown : null,
          btnUpdate ? (
            <Button
              type="secondary"
              htmlType="button"
              onClick={onBtnEdit}
              key="clearActionBtn"
              shape="round"
              className="delete-btn-none"
              style={{
                marginRight: "0px",
              }}
              form={setFormName}
            >
              {btnUpdate}
            </Button>
          ) : null,
        ];

  const extraProps =
    width > breakpoint
      ? {
          WOSelectCount: parseInt(WOSelectCount),
          clearSelection: clearSelection ? clearSelection : null,
          handleClearFilters: handleClearFilters ? handleClearFilters : null
        }
      : {};

  /** Useful When Data View */
  return (
    <CustomPageHeader
      subTitle={subTitle}
      title={title}
      backIcon={<BackIcon height="30px" />}
      {...extraProps}
      onBack={
        backBtnPath
          ? () => {
              history(backBtnPath, routeState ? routeState : {}); //TODO
            }
          : false
      }
      extra={extraTablePageHeaderContent}
      className={width > breakpoint ? "page-header" : "page-header responsive"}
      customStyles={customStyles}
    >
      {divider && <Divider style={{ margin: "0" }} />}
      {children}
    </CustomPageHeader>
  );
};

PageHead.propTypes = {
  backBtnPath: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default PageHead;
