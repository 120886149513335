import React, { useState } from "react";
import {
  Select,
  Spin,
  Skeleton,
  Divider,
  Typography,
  Space,
  Table,
  Input,
} from "antd";
import { CloseCircleOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import PageHead from "../../components/PageHead";
import apiCall from "../../services/apiCall";
import ToastNotification from "../../helpers/ToastNotification";
import useViewPort from "../../services/responsiveHelper";
import ListClients from "../../components/ListClients";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useLocation } from "react-router-dom";
import { SourceConstants, UserStatusConstants } from "../../helpers/Constants";
import { useSelector } from "react-redux";
import { ReactComponent as EmailIcon } from "../../assets/icon_mail.svg";
import PopOver from "../../components/PopOver";
import AddWorkorderModal from "../../components/modals/AddWorkorderModal";
import ReplaceWorkorderModal from "../../components/modals/ReplaceWorkorderModal";
import RemoveWorkorderModal from "../../components/modals/RemoveWorkorderModal";
import { useDebounceFn } from "../../hooks";
// import "../../styles/rollover-styles.css"
// import "../../styles/styles.css"
const { Text } = Typography;

// ------------ HELPERS ------------
const removeFalsyValueKeys = (obj) =>
  Object.fromEntries(Object.entries(obj).filter((entry) => Boolean(entry[1])));

const apiLabelStartsWith = (label, startsWith) =>
  label.toLowerCase().startsWith(startsWith.toLowerCase());

const isSourceValid = (source) =>
  new Set(Object.values(SourceConstants)).has(source);

const isStatusValid = (status) =>
  new Set(["Active", "InActive", "Pending"]).has(status);

const fromLSOrDefault = (key, defaultValue) =>
  localStorage.getItem(key) ?? defaultValue;

// to avoid local storage key collisions
const lSKeyPrefix = (value) =>
  value === "" ? value : `clientProctors-${value}`;

// ------------ CONSTANTS ------------
const workOrderNoFilterLSKey = lSKeyPrefix("workOrderNo");
const proctorNameFilterLSKey = lSKeyPrefix("proctorName");
const emailFilterLSKey = lSKeyPrefix("email");
const tableCurrentPageLSKey = lSKeyPrefix("tableCurrentPage");
const sourceFilterLSKey = lSKeyPrefix("source");
const statusFilterLSKey = lSKeyPrefix("status");
const clientIdLSKey = lSKeyPrefix("clientId");
const facilityIdLSKey = lSKeyPrefix("facilityId");
const cellHorizontalPadding = "24px";

// ------------ CUSTOM HOOKS ------------
const useFilteredValue = (value) =>
  React.useMemo(() => (value ? [value] : null), [value]);

const useLocalStorageEffect = (
  key,
  value,
  removalCondition = value === undefined
) => {
  React.useEffect(() => {
    if (removalCondition) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }, [key, value, removalCondition]);
};

// ------------ COMPONENTS ------------
const ClientProctors = () => {
  const { width } = useViewPort();
  const breakpoint = 670;
  // VW - viewport width
  const isVWLessThanBreakpoint = width < breakpoint;
  const history = useNavigate();
  const location = useLocation();
  const [clientList, setClientList] = useState({
    currentPageClients: [],
    totalClients: [],
  });
  const [totalCount, setTotalCount] = useState(0);
  const [pageLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [currentPage, setCurrentPage] = useState(() => {
    if (isVWLessThanBreakpoint) {
      return 1;
    }
    const currentPageInLS = localStorage.getItem(tableCurrentPageLSKey);
    const currentPageConverted = Number(currentPageInLS);
    if (Number.isFinite(currentPageConverted)) {
      return Math.max(1, currentPageConverted);
    }
    return 1;
  });

  const { source, status, workOrderNo, email, proctorName } =
    location.state || {};

  const [filters, setFilters] = useState(() => {
    const res = {
      workOrderNo,
      email,
      proctorName,
      source,
      status,
    };
    if (!isVWLessThanBreakpoint) {
      res.workOrderNo ??= fromLSOrDefault(workOrderNoFilterLSKey);
      res.email ??= fromLSOrDefault(emailFilterLSKey);
      res.proctorName ??= fromLSOrDefault(proctorNameFilterLSKey);
      res.clientId ??= fromLSOrDefault(clientIdLSKey);
      res.facilityId ??= fromLSOrDefault(facilityIdLSKey);
      if (!res.status) {
        const statusFromLS = localStorage.getItem(statusFilterLSKey);
        if (isStatusValid(statusFromLS)) {
          res.status = statusFromLS;
        }
      }
      if (!res.source) {
        const sourceFromLS = localStorage.getItem(sourceFilterLSKey);
        if (sourceFromLS === null) {
          // first-time visit
          res.source = SourceConstants.SALUX_VAULT;
        } else if (isSourceValid(sourceFromLS)) {
          res.source = sourceFromLS;
        } else {
          res.source = "";
        }
      }
    }
    return res;
  });

  const clientsFetchStatus = useSelector((state) => state.organisations.status);
  const clients = useSelector((state) => state.organisations.data);
  const facilitiesFetchStatus = useSelector((state) => state.facilities.status);
  const facilities = useSelector((state) => state.facilities.data);


  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openReplaceModal, setOpenReplaceModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(() => {
    const rolloverEnabled = localStorage.getItem("rolloverEnabled");
    if (rolloverEnabled == "true") return true;
    else if (rolloverEnabled == "false") return false;

    return false;
  });

  const [customPageSize, setCustomPageSize] = useState(10);
  const populateProctors = React.useCallback(
    async (page, size, filtersLocal) => {
      setLoadingList(true);
      const payload = removeFalsyValueKeys({
        workOrder: filtersLocal.workOrderNo,
        proctorName: filtersLocal.proctorName,
        email: filtersLocal.email,
        facilityId: filtersLocal.facilityId,
        clientId: filtersLocal.clientId,
        source: filtersLocal.source,
        status: filtersLocal.status,
      });
      const data = await apiCall.getClientProctors(page - 1, size, payload);
      if (data.status === 200) {
        const fetchedClientList = data?.data?.docs;
        setClientList((prev) => {
          const newClients = fetchedClientList.map((client) => ({
            _id: client._id,
            workOrders: client.workOrders,
          }));

          const mergedClients = [
            ...prev.totalClients.filter(
              (existingClient) =>
                !newClients.some(
                  (newClient) => newClient._id === existingClient._id
                )
            ),
            ...newClients,
          ];

          return {
            currentPageClients: fetchedClientList,
            totalClients: mergedClients,
          };
        });
        setTotalCount(data?.data?.totalDocs);
        const pageFromApi = data?.data?.page;
        // serves two purposes
        // 1. the `Table` component won't display pagination ui
        // 2. breaks the infinite fetch cycle as the `currentPage` value is a dependency of the data fetch
        if (pageFromApi === undefined || fetchedClientList.length === 0) {
          setCurrentPage(undefined);
        }
      } else {
        ToastNotification("error", "Error fetching User records.");
      }
      setLoadingList(false);
    },
    []
  );

  React.useEffect(() => {
    if (typeof currentPage !== "number") {
      return;
    }
    populateProctors(currentPage, customPageSize, filters);
  }, [filters, currentPage, populateProctors, customPageSize]);

  useLocalStorageEffect(
    tableCurrentPageLSKey,
    currentPage,
    typeof currentPage !== "number"
  );

  useLocalStorageEffect(clientIdLSKey, filters.clientId);

  useLocalStorageEffect(facilityIdLSKey, filters.facilityId);

  useLocalStorageEffect(statusFilterLSKey, filters.status);

  React.useEffect(() => {
    localStorage.setItem(
      sourceFilterLSKey,
      !filters.source ? "" : filters.source
    );
  }, [filters.source]);

  React.useEffect(() => () => {
    if (history.action === "POP") {
      history(1);
    }
  });

    /* To remove the scrollbar from the <main> element which is being auto generated by Antd */
  React.useEffect(() => {
    const contentDiv = document.querySelector('.ant-layout-content');
    if (contentDiv) {
      contentDiv.classList.add('no-overflow-y');
    }
    return () => {
      if (contentDiv) {
        contentDiv.classList.remove('no-overflow-y');
      }
    };
  }, []);

  const onFilterChange = React.useCallback((newValue, changedKey) => {
    let filtersChanged = true;
    setFilters((curFilters) => {
      if (
        curFilters[changedKey] === newValue ||
        (!curFilters[changedKey] && !newValue)
      ) {
        filtersChanged = false;
        return curFilters;
      }
      const newFilters = { ...curFilters };
      if (newValue) {
        newFilters[changedKey] = newValue;
      } else {
        delete newFilters[changedKey];
      }
      return newFilters;
    });
    if (filtersChanged) {
      setCurrentPage(1);
    }

    setSelectedRowKeys([]);
    return filtersChanged;
  }, []);

  const getSelectedFacility = React.useCallback(
    (id) => {
      if (!id) {
        // skip iterating facilities
        return null;
      }
      return facilities.find(({ value }) => value === id) ?? null;
    },
    [facilities]
  );

  const getClientById = React.useCallback(
    (id) => clients.find(({ value }) => value === id) ?? null,
    [clients]
  );

  const getFacilitiesByClientId = React.useCallback(
    (id) => facilities.filter(({ clientId }) => clientId === id) ?? null,
    [facilities]
  );

  const filteredFacilities = React.useMemo(() => {
    if (!filters.clientId) {
      // skip iterating facilities
      return facilities;
    }
    return getFacilitiesByClientId(filters.clientId);
  }, [facilities, filters.clientId, getFacilitiesByClientId]);

  const handleEnableCheckboxes = () => {
    setShowCheckboxes((prev) => {
      localStorage.setItem("rolloverEnabled", !prev);
      return !prev;
    });
    setSelectedRowKeys([]);
  };

  const handleClearFilters = () => {
    // Reset filters
    setFilters({
      email: undefined,
      proctorName: undefined,
      source: "SALUX_VAULT",
      status: "",
      workOrderNo: undefined,
      clientId: undefined,
      facilityId: undefined,
    });
  
    // Reset page
    setCurrentPage(1);
  
    // Keys to clear from local storage
    const storageKeys = [
      "workOrderNo",
      "proctorName",
      "email",
      "clientName",
      "clientId",
      "facilityName",
      "facilityId",
      "tableCurrentPage",
      "status",
      "source",
    ];
  
    // Clear local storage
    storageKeys.forEach((key) => localStorage.removeItem(`clientProctors-${key}`));

    // Clear selection
    if(selectedRowKeys.length)
      setSelectedRowKeys([]);
  };
  

  const handleRowSelectionChange = (currentPageSelectedKeys) => {
    const updatedKeysSet = new Set(selectedRowKeys);

    currentPageSelectedKeys.forEach((key) => updatedKeysSet.add(key));

    clientList.currentPageClients.forEach((record) => {
      if (!currentPageSelectedKeys.includes(record._id)) {
        updatedKeysSet.delete(record._id);
      }
    });

    setSelectedRowKeys(Array.from(updatedKeysSet));
  };

  const handleCancel = (modal, success) => {
    if (modal === "add") setOpenAddModal((val) => !val);
    else if (modal === "replace") setOpenReplaceModal((val) => !val);
    else if (modal === "remove") setOpenRemoveModal((val) => !val);

    if(success) {
      setSelectedRowKeys([]);
      populateProctors(currentPage, customPageSize, filters);
    }
  };

  const columns = [
    {
      title: (
        <TableTitleWithInput
          titleText="Workorder"
          inputType="search"
          searchPlaceholder="Workorder"
          localStorageKey={workOrderNoFilterLSKey}
          onFilterChange={React.useCallback(
            (v) => onFilterChange(v, "workOrderNo"),
            [onFilterChange]
          )}
          searchDefaultValue={filters.workOrderNo}
        />
      ),
      filteredValue: useFilteredValue(filters.workOrderNo),
      dataIndex: "workOrderNo",
      key: "workOrderNo",
      width: "15%",
      render: (_text, record) => {
        const data = record?.workOrders || [];
        const remainingData = record?.workOrders?.slice(1) || [];

        if (data.length > 0) {
          const content = (
            <div>
              {remainingData.map((item, index) => (
                <div key={index}>
                  <span>{item.workOrderNo}</span>
                </div>
              ))}
            </div>
          );

          return (
            <Space>
              {data[0].workOrderNo}
              {remainingData.length > 0 && (
                <PopOver content={content}>
                  <Text
                    className="popover-workorder-text"
                    underline={true}
                  >{`+${remainingData.length}`}</Text>
                </PopOver>
              )}
            </Space>
          );
        }
      },
    },
    {
      title: (
        <TableTitleWithInput
          titleText="Proctor Name"
          inputType="search"
          searchPlaceholder="Name"
          localStorageKey={proctorNameFilterLSKey}
          onFilterChange={React.useCallback(
            (v) => onFilterChange(v, "proctorName"),
            [onFilterChange]
          )}
          searchDefaultValue={filters.proctorName}
        />
      ),
      width: "15%",
      filteredValue: useFilteredValue(filters.proctorName),
      dataIndex: ["firstName", "lastName"],
      key: "proctorName",
      filterMultiple: false,
      render: (_text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: (
        <TableTitleWithInput
          titleText="Email"
          inputType="search"
          searchPlaceholder="Email"
          localStorageKey={emailFilterLSKey}
          onFilterChange={React.useCallback(
            (v) => onFilterChange(v, "email"),
            [onFilterChange]
          )}
          searchDefaultValue={filters.email}
        />
      ),
      width: "15%",
      filteredValue: useFilteredValue(filters.email),
      dataIndex: "email",
      key: "email",
      render: (email) => (
        <span className="table-cell-container">
          <div className="table-cell-icon">
            <EmailIcon />
          </div>
          {email}
        </span>
      ),
    },
    {
      title: (
        <TableTitleWithInput
          titleText="Client Name"
          inputType="select-search"
          searchPlaceholder="Client"
          onFilterChange={(v) => {
            if (onFilterChange(v, "clientId")) {
              onFilterChange("", "facilityId");
            }
          }}
          options={clients}
          selectedOption={React.useMemo(
            () => clients.find(({ value }) => value === filters.clientId),
            [filters.clientId, clients]
          )}
        />
      ),
      width: "22%",
      filteredValue: useFilteredValue(filters.clientName),
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: (
        <TableTitleWithInput
          titleText="Facility Name"
          inputType="select-search"
          searchPlaceholder="Facility"
          onFilterChange={(v) => {
            // v can be a valid facilityId or an empty string
            let selectedFacility, client;
            if (
              onFilterChange(v, "facilityId") &&
              (selectedFacility = getSelectedFacility(v)) !== null &&
              (client = getClientById(selectedFacility.clientId)) !== null
            ) {
              onFilterChange(client.value, "clientId");
            }
          }}
          options={filteredFacilities}
          selectedOption={React.useMemo(
            () =>
              filteredFacilities.find(
                ({ value }) => value === filters.facilityId
              ),
            [filters.facilityId, filteredFacilities]
          )}
        />
      ),
      width: "22%",
      filteredValue: useFilteredValue(filters.facilityName),
      dataIndex: "facilityName",
      key: "facilityName",
      render: (_text, record) => {
        const data = record?.facilityNames || [];
        const remainingData = record?.facilityNames?.slice(1) || [];

        if (data.length > 0) {
          const content = (
            <div>
              {remainingData.map((item, index) => (
                <div key={index}>
                  <span>{item}</span>
                </div>
              ))}
            </div>
          );

          return (
            <div className="facility-names-wrapper">
              <div className="facility-name">{data[0]}</div>
              <div className="remaining-facility-count">
                {remainingData.length > 0 && (
                  <PopOver content={content}>
                    <Text
                      className="popover-workorder-text"
                      underline={true}
                    >{`+${remainingData.length}`}</Text>
                  </PopOver>
                )}
              </div>
            </div>
          );
        }
      },
    },
    {
      title: <span className="status-col-head"><span className="status-col-border"></span>Status</span>,
      dataIndex: "status",
      key: "status",
      width: "8%",
      align: "left",
      render: (status) => {
        let color;
        if (status === "Active") color = "rgb( 98, 181, 229)";
        else if (status === "InActive") color = "rgb( 154,154,154)";
        else color = "rgb( 232,119,34)";

        return (
          <span className="proctor-status">
            <span className="status" style={{ backgroundColor: color }} />
            <span className="status-text">{status ? status : "Pending"}</span>
          </span>
        );
      },
    },
    // dummy column for extra spacing
    {
      title: "",
      className: "last-el",
      width: "auto",
    }
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 36,
        color: "rgb(0, 48, 135)",
        fontWeight: "bold",
      }}
      spin
    />
  );


  const pageHeaderProps = {
    btnAdd: "Add",
    onBtnClick: () => {
      history("/client-proctor-form", {
        state: { ...location.state, isAddClient: true },
      });
    },
    actionButtons: true,
    onAddBtnClick: () => setOpenAddModal(true),
    onReplaceBtnClick: () => setOpenReplaceModal(true),
    onRemoveBtnClick: () => setOpenRemoveModal(true),
    WOSelectCount: selectedRowKeys?.length || 0,
    enableSelection: handleEnableCheckboxes,
    showCheckboxes: showCheckboxes,
    clearFilters: handleClearFilters,
    clearSelection: setSelectedRowKeys,
    handleClearFilters
  };

  const rowSelection = {
    selectedRowKeys,
    onCell: () => ({
      onClick: (event) => {
        event.stopPropagation();
      },
    }),
    onChange: (currentPageSelectedKeys) => {
      // Compute the updated selected keys:
      const updatedKeys = new Set(selectedRowKeys); // Start with all previously selected keys
      const currentPageKeys = new Set(currentPageSelectedKeys); // Keys from the current page

      // Add new selections from the current page
      currentPageKeys.forEach((key) => updatedKeys.add(key));

      // Remove deselected keys (keys not in currentPageKeys but in selectedRowKeys)
      selectedRowKeys.forEach((key) => {
        if (!currentPageKeys.has(key)) {
          updatedKeys.delete(key);
        }
      });

      handleRowSelectionChange(Array.from(updatedKeys)); // Convert Set back to array and update state
    },

    getCheckboxProps: (record) => {
      return {
        disabled: record.source === "WRE",
        className: showCheckboxes && record.source !== "WRE"
          ? "table-row-checkbox-visible"
          : "table-row-checkbox-hide",
      };
    },
    hideSelectAll: (filters.source === "WRE" || !clientList.currentPageClients.length || !showCheckboxes)
  };

  const handleListingSize = (value) => {
    setCustomPageSize(value);
    setCurrentPage(1);
    setSelectedRowKeys([]);
  };

  const dropdownFilters = (
    <span className="select">
      <Space>
        <Select
          defaultValue={SourceConstants.SALUX_VAULT}
          value={filters.source}
          onSelect={(value) => {
            setFilters((prev) => ({ ...prev, source: value }));
            setCurrentPage(1);
            history("/client-proctor", {
              state: { ...location.state, source: value },
              replace: true,
            });
          }}
          className="user-type-dropdown-source"
        >
          <Select.Option value="">All Users</Select.Option>
          <Select.Option value={SourceConstants.SALUX_VAULT}>
            Salux Vault
          </Select.Option>
          <Select.Option value={SourceConstants.WRE}>WRE</Select.Option>
        </Select>
        <Select
          defaultValue=""
          value={filters.status}
          onSelect={(value) => {
            setFilters((prev) => ({ ...prev, status: value }));
            setCurrentPage(1);
            history("/client-proctor", {
              state: { ...location.state, status: value },
              replace: true,
            });
          }}
          className="user-type-dropdown-status"
        >
          <Select.Option value={UserStatusConstants.ALL_USERS}>
            All Users
          </Select.Option>
          <Select.Option value={UserStatusConstants.ACTIVE}>
            Active Users
          </Select.Option>
          <Select.Option value={UserStatusConstants.INACTIVE}>
            InActive Users
          </Select.Option>
          <Select.Option value={UserStatusConstants.PENDING}>
            Pending Users
          </Select.Option>
        </Select>
      </Space>
    </span>
  );

  const showList = isVWLessThanBreakpoint ? (
    <div id="scrollableDiv" className="infinite-scroll-container">
      <InfiniteScroll
        dataLength={totalCount}
        next={() => {
          if (loadingList) {
            return;
          }
          setCurrentPage((currentPage) => currentPage + 1);
        }}
        hasMore={totalCount < 100}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>It is all, nothing more </Divider>}
        scrollableTarget="scrollableDiv"
        className="infinite-scroll"
      >
        <ListClients data={clientList.currentPageClients} />
      </InfiniteScroll>
    </div>
  ) : (
    <div className="table-container fixed-header-table">
      <Table
        components={{
          header: {
            cell: (props) => (
              <th {...props} className="table-container-header" />
            ),
          },
          body: {
            cell: (props) => <td {...props} className="table-container-data" />,
          },
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={clientList.currentPageClients}
        rowKey={(record) => record._id}
        loading={loadingList}
        pagination={{
          total: totalCount,
          showSizeChanger: false,
          pageSize: customPageSize,
          current: currentPage,
          showTotal: () => (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Select
                value={customPageSize}
                onSelect={handleListingSize}
                options={[10, 20, 50, 100].map((value) => ({
                  label: `${value} / page`,
                  value: value,
                }))}
                style={{ width: 120 }}
                tabIndex={1}
              />
            </div>
          ),
          className: "custom-pagination",
        }}
        onChange={({ current }) => {
          setCurrentPage(current);
        }}
        rowClassName={(_record, index) =>
          index % 2 === 0
            ? "custom-row eCloud-table-row-light"
            : "custom-row eCloud-table-row-dark"
        }
        onRow={(recordIndex) => ({
          onClick: () =>
            history(`/client-proctor-form/${recordIndex._id}`, {
              state: { ...location.state, id: recordIndex._id },
            }),
        })}
        className="eCloud-table top-border-none"
      />
    </div>
  );

  // Handle loading or error states
  if (clientsFetchStatus === "loading" || facilitiesFetchStatus === "loading") {
    return <div>Loading...</div>;
  }
  if (clientsFetchStatus === "failed" || facilitiesFetchStatus === "failed") {
    return <div>Error loading data.</div>;
  }

  return (
    <div className="main-container">
      <PageHead
        title={"Client Proctors"}
        dropDown={dropdownFilters}
        {...pageHeaderProps}
        width={width}
        breakpoint={breakpoint}
        customStyles
      >
        {pageLoading ? (
          <Spin indicator={antIcon} className="loader" />
        ) : (
          showList
        )}
      </PageHead>
      {openAddModal ? (
        <AddWorkorderModal
          visible={openAddModal}
          onConfirm={null}
          onCancel={(modal, success) => handleCancel(modal, success)}
          selectedRowKeys={selectedRowKeys}
          // clearSelection={() => setSelectedRowKeys([])}
        />
      ) : openReplaceModal ? (
        <ReplaceWorkorderModal
          visible={openReplaceModal}
          onConfirm={null}
          onCancel={(modal, success) => handleCancel(modal, success)}
          clientList={clientList.totalClients}
          selectedRowKeys={selectedRowKeys}
          // clearSelection={() => setSelectedRowKeys([])}
        />
      ) : openRemoveModal ? (
        <RemoveWorkorderModal
          visible={openRemoveModal}
          onConfirm={null}
          onCancel={(modal, success) => handleCancel(modal, success)}
          clientList={clientList.totalClients}
          selectedRowKeys={selectedRowKeys}
          // clearSelection={() => setSelectedRowKeys([])}
        />
      ) : null}
    </div>
  );
};

export default ClientProctors;

const TableTitleWithChildren = ({ titleText, children }) => {
  return (
    <Space
      direction="vertical"
      size={12}
      className="table-header-with-children"
    >
      <span
        className={"table-header-children"}
      >
        {titleText}
      </span>
      {children}
    </Space>
  );
};

const TableTitle = (props) => {
  return <TableTitleWithChildren {...props} children={null} />;
};

const TableTitleWithInput = ({
  titleText,
  inputType, // "search" | "select-search"
  ...inputProps
}) => {
  return (
    <TableTitleWithChildren titleText={titleText}>
      {inputType === "search" ? (
        <Search {...inputProps} />
      ) : inputType === "select-search" ? (
        <SelectSearch {...inputProps} />
      ) : null}
    </TableTitleWithChildren>
  );
};

const ClearIcon = () => (
  <CloseCircleOutlined
    className="clear-icon"
  />
);

const SearchIcon = () => (
  <SearchOutlined
    className="search-icon"
  />
);

const Search = ({
  searchDefaultValue,
  localStorageKey,
  searchPlaceholder,
  onFilterChange,
}) => {
  const [searchText, setSearchText] = React.useState(searchDefaultValue ?? "");
  const [onFilterChangeDebounce, cancelFilterChangeDebounceCall] =
    useDebounceFn(onFilterChange, 500);

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, searchText);
  }, [localStorageKey, searchText]);

  return (
    <Input
      allowClear={{ clearIcon: <CloseCircleOutlined className="clear-icon"/>}}
      placeholder={searchPlaceholder}
      value={searchText}
      onChange={(e) => {
        const newSearchText = e.target.value;
        if (newSearchText === "") {
          // the user cleared the entire text manually
          cancelFilterChangeDebounceCall();
          onFilterChange("");
        } else {
          onFilterChangeDebounce(newSearchText);
        }
        setSearchText(newSearchText);
      }}
      onClear={() => {
        cancelFilterChangeDebounceCall();
        onFilterChange("");
      }}
      suffix={searchText.length === 0 ? <SearchIcon /> : undefined}
      className="search-input-filter col-search-text-input"
    />
  );
};

const SelectSearch = ({
  searchPlaceholder,
  options,
  onFilterChange,
  selectedOption,
}) => {
  const getSelectedOptionLabel = React.useCallback(
    () => selectedOption?.label ?? "",
    [selectedOption?.label]
  );
  const [searchText, setSearchText] = React.useState(getSelectedOptionLabel);

  React.useEffect(() => {
    setSearchText(getSelectedOptionLabel());
  }, [getSelectedOptionLabel]);

  return (
    <Select
      className={`search-select-input-filter${
        selectedOption === undefined && searchText.length === 0
          ? ""
          : " hide-arrow-on-hover h-search-select"
      }`}
      showSearch={true}
      popupMatchSelectWidth={false}
      suffixIcon={<SearchIcon />}
      allowClear={{ clearIcon: <CloseCircleOutlined className="clear-icon"/>}}
      placeholder={searchPlaceholder}
      onFocus={() => setSearchText(getSelectedOptionLabel())}
      searchValue={searchText}
      onSearch={setSearchText}
      onClear={() => {
        onFilterChange("");
        setSearchText("");
      }}
      filterOption={(searchText, { label }) =>
        apiLabelStartsWith(label, searchText)
      }
      onSelect={onFilterChange}
      // optionFilterProp="label"
      optionLabelProp="label"
      options={options}
      value={selectedOption?.value}
    />
  );
};
