import { Button, Col, Modal, Row, Select, Typography } from "antd";
import React, { useState } from "react";
import { ReactComponent as RemoveIcon } from "../../assets/icon_remove.svg";

import moment from "moment";

const ModalWorkorderCard = ({
  data,
  removeWorkOrder,
  disabled,
  cardStyles,
  isReplaceWoModal = false,
  onSelect,
  value,
  success,
  replacedWith
}) => {
  const { Text } = Typography;

  const stringToDate = (date) => {
    if (date) {
      return moment(date, "YYYYMMDD").format("MMM DD, YYYY");
    }
    return date;
  };

  return (
    <div className="modal-workorder-details-card" style={{ ...cardStyles }}>
      <Row justify="space-between" align="middle">
        <Col flex={"row"}>
          {isReplaceWoModal && success ? (
            <>
              <Text className="replace-wordorder-static-text">Workorder replaced from{" "}</Text>
              <span className="replace-wordorder-badge">
                {data.workOrderNo}
              </span>{" "}
              <Text className="replace-wordorder-static-text">to{" "}</Text>
              <Text className="replace-wordorder-badge">
                {replacedWith}
              </Text>{" "}
            </>
          ) : (
            <> 
              Workorder: <Text strong>{data.workOrderNo}</Text>{" "}
            </>
          )}
        </Col>
        <Col flex={"none"}>
          {!isReplaceWoModal && !disabled && (
            <Button
              type="text"
              onClick={removeWorkOrder}
              disabled={disabled}
              shape="circle"
              icon={
                <RemoveIcon
                  className={"action-remove"}
                />
              }
              className="action-button delete"
            />
          )}
        </Col>
      </Row>
      <Row className="workorder-details-container">
        <Col span={"5"} className="workorder-details">
          <Text type="secondary">Organization</Text>
          <Text>{data.clientName}</Text>
        </Col>
        <Col span={"10"} className="workorder-details">
          <Text type="secondary">Facility</Text>
          <Text>{data.facilityName}</Text>
        </Col>
        <Col span={"7"} className="workorder-details">
          <Text type="secondary">Start Date</Text>
          <Text>{stringToDate(data.startDate)}</Text>
        </Col>
      </Row>

      <Row className="workorder-details-container">
        <Col span={"5"} className="workorder-details">
          <Text type="secondary">End Date</Text>
          <Text>{stringToDate(data.endDate)}</Text>
        </Col>
        <Col span={"auto"} className="workorder-details">
          <Text type="secondary">Address</Text>
          <Text>{data.woAddressLine1}</Text>
        </Col>
      </Row>

      <Row className="workorder-details-container">
        <Col span={"auto"} className="workorder-details">
          <Text type="secondary">Subscription Type</Text>
          <Select
            className="select-subscription-type replace-wo-input-select"
            value={value}
            style={{ height: "39px"}}
            size="medium"
            onSelect={onSelect}
            disabled={disabled}
            dropdownStyle={{ minWidth: '220px'}}
          >
            <Select.Option value="TO">Test Only(TO)</Select.Option>
            <Select.Option value="TN">Test Notify(TN)</Select.Option>
            <Select.Option value="TT">Test Train(TT)</Select.Option>
            <Select.Option value="TC">Test Counsel(TC)</Select.Option>
            <Select.Option value="TTN">Test Train Notify(TTN)</Select.Option>
            <Select.Option value="TTC">Test Train Counsel(TTC)</Select.Option>
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export default ModalWorkorderCard;
