import React from "react";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const CustomModal = ({ visible, onConfirm, onCancel, title, children }) => {
  return (
    <Modal
      centered
      open={visible}
      footer={null}
      closable={false}
      maskClosable={false}
      width={633}
      className="modal-container"
      title={
        <div className="custom-modal-group">
          <span className="custom-modal-title">{title}</span>
          <Button
            icon={<CloseOutlined />}
            className="cancel-button"
            onClick={onCancel}
          />
        </div>
      }
    >
      <div className="custom-modal-children">
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
