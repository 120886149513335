import React, { useMemo } from "react";
import { Timeline } from "antd";
import dayjs from "dayjs";
import { InfoCircleFilled, CheckCircleFilled } from "@ant-design/icons";

const TimelineItem = ({ data }) => {
  const getActionDetails = (action) => {
    const actionsMap = {
      PROCTOR_ACTIVATION: {
        label: "Registered Successfully",
        color: "green",
        icon: (
          <CheckCircleFilled className="timeline-checked" />
        ),
      },
      PROCTOR_RESEND_EMAIL_VERIFICATION: {
        label: "Registration link re-sent to email",
        color: "orange",
        icon: (
          <InfoCircleFilled className="timeline-info" />
        ),
      },
      PROCTOR_CREATION: {
        label: "User created, Registration link sent to the email",
        color: "orange",
        icon: (
          <InfoCircleFilled className="timeline-info" />
        ),
      },
    };

    return (
      actionsMap[action] || {
        label: "Unknown Action",
        color: "gray",
        icon: null,
      }
    );
  };

  const timelineItems = useMemo(() => {
    return data.map((item, index) => {
      const { label, color, icon } = getActionDetails(item.action);

      return {
        color,
        dot: icon,
        children: (
          <div>
            <p>{label}</p>
            <p className="timeline-item">
              {dayjs(item.timestamp).format("MM/DD/YYYY [at] HH:mm")}
            </p>
          </div>
        ),
        className: index === data.length - 1 ? "timeline-last-item" : "",
      };
    });
  }, [data]);

  return <Timeline items={timelineItems} />;
};

export default TimelineItem;
