import http from "./httpService";
import { jwtDecode } from "jwt-decode";
import moment from "moment";

http.setJWT(getJWT());

function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("token");
    return jwt ? jwtDecode(jwt) : false;
  } catch (exception) {
    console.log(exception);
    return null;
  }
}

async function login(email, password) {
    try {
        const res = await http.post("/login", {
            email: email,
            password: password
        });
        if (res.data.token) {
            const timeStamp = moment();
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("firstName", res.data.firstName);
            localStorage.setItem("lastName", res.data.lastName);
            localStorage.setItem("lastTimeStamp", timeStamp);
            http.setJWT(getJWT());
        }
        return res;
    } catch (exception) {
        console.log(exception);
        if (exception.response) return exception.response;
        return false;
    }
}
function getJWT() {
    return localStorage.getItem("token");
}
async function sendForgotPasswordLink(email) {
    try {
        return await http.post("/password-reset", {
            email,
            type: "Admin"
        });
    } catch (exception) {
        console.log(exception.response.data);
        return exception.response;
    }
}
async function resetPassword(token, password) {
    try {
        const res = await http.post(`/password-reset/${token}`, {
            password: password
        });
        return res.status === 200;
    } catch (exception) {
        console.log(exception);
        return false;
    }
}
async function activatePassword(token, password) {
    try {
        const res = await http.post(`/users/${token}`, {
            password: password
        });
        return res.status === 200;
    } catch (exception) {
        console.log(exception);
        return false;
    }
}
async function activatePasswordProctors(token, password) {
    try {
        const res = await http.post(`/proctors/${token}`, {
            password: password
        });
        return res.status === 200;
    } catch (exception) {
        console.log(exception);
        return false;
    }
}
async function getAdminUsers(page, size, filter) {
    try {
        const res = await http.get("/users", {
            params: { page: page, size: size, ...filter }
        });

        if (res.status === 200) {
            return res;
        }

        return false;
    } catch (exception) {
        console.log(exception);
        return false;
    }
}
async function getAdminUsersByFilter(page, size, filterType) {
    try {
        const res = await http.get("/users/filter", {
            params: { page: page, size: size, by: filterType }
        });
        if (res.status === 200) {
            return res;
        }
        return false;
    } catch (exception) {
        console.log(exception);
        return false;
    }
}
async function getAdminUsersBySearch(page, size, searchValue) {
    try {
        const res = await http.get("/users/search", {
            params: { page: page, size: size, searchText: searchValue }
        });
        if (res.status === 200) return res;
        return false;
    } catch (exception) {
        console.log(exception);
        return false;
    }
}
async function getClientProctors(page, size, payload) {
  try {
    const res = await http.get("/proctors", {
      params: { page: page, size: size, ...payload },
    });
    if (res.status === 200) return res;
    return false;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}
async function getClientProctorsBySearch(page, size, searchValue) {
    try {
        const res = await http.get("/proctors/search", {
            params: { page: page, size: size, searchText: searchValue }
        });
        if (res.status === 200) return res;
        return false;
    } catch (exception) {
        console.log(exception);
        return false;
    }
}
async function getClientProctorsByFilter(page, size, filterType) {
    try {
        const res = await http.get("/proctors/filter", {
            params: { page: page, size: size, by: filterType }
        });
        if (res.status === 200) {
            return res;
        }
        return false;
    } catch (exception) {
        console.log(exception);
        return false;
    }
}

async function addAdminUser(formValues) {
    try {
        return await http.post("/users", formValues);
    } catch (exception) {
        console.log(exception);
        return exception.response;
    }
}
async function addClientProctor(formValues) {
    try {
        return await http.post("/proctors", formValues);
    } catch (exception) {
        console.log(exception);
        return exception.response;
    }
}
async function resendInvite(email) {
    try {
        return await http.post("/users/resendInvite", { email: email });
    } catch (exception) {
        console.log(exception);
        return exception.response;
    }
}
async function resendInviteProctors(email) {
    try {
        return await http.post("/proctors/resendInvite", { email: email });
    } catch (exception) {
        console.log(exception);
        return exception.response;
    }
}
async function editAdminUser(formValues) {
    try {
        return await http.put("/users", formValues);
    } catch (exception) {
        console.log(exception);
        return exception.response;
    }
}
async function editClientProctor(formValues, userId) {
    try {
        return await http.patch(`/proctors/${userId}`, formValues);
    } catch (exception) {
        console.log(exception);
        return exception.response;
    }
}
async function getUserByEmail(userId) {
    try {
        const res = await http.get(`/users/${userId}`);
        if (res.status === 200) return res;
        return res;
    } catch (exception) {
        console.log(exception);
        return exception.response;
    }
}
async function getClientById(userId) {
    try {
        const res = await http.get(`/proctors/${userId}`);
        if (res.status === 200) return res;
        return res;
    } catch (exception) {
        console.log(exception);
        return exception.response;
    }
}

async function getWorkorderDetails(workorderId) {
    try {
        const res = await http.get("/workorder", {
            params: { id: workorderId }
        });
        if (res.status === 200) {
            return res;
        }
        return res;
    } catch (exception) {
        return exception.response;
    }
}

async function addBulkWorkorders(payLoad) {
    try {
      const res = await http.post("proctors/addWorkOrder", payLoad);
      return res;
    } catch (error) {
      return error.response;
    }
  }
  
  async function replaceBulkWorkorders(payLoad) {
      try {
        const res = await http.post("proctors/replaceWorkOrder", payLoad);
        return res;
      } catch (error) {
        return error.response;
      }
    }
  
    async function deleteBulkWorkorders(payLoad) {
      try {
        const res = await http.post("proctors/deleteWorkOrder", payLoad);
        return res;
      } catch (error) {
        return error.response;
      }
    }

    
const apiCall = {
    getCurrentUser,
    login,
    sendForgotPasswordLink,
    resetPassword,
    getAdminUsers,
    getAdminUsersByFilter,
    getAdminUsersBySearch,
    getClientProctors,
    getClientProctorsBySearch,
    getClientProctorsByFilter,
    addAdminUser,
    addClientProctor,
    editAdminUser,
    editClientProctor,
    getUserByEmail,
    getClientById,
    resendInvite,
    resendInviteProctors,
    activatePassword,
    activatePasswordProctors,
    getWorkorderDetails,
    addBulkWorkorders,
    replaceBulkWorkorders,
    deleteBulkWorkorders
};
export default apiCall;
