import React from "react";
import { SetIsUserLoggedInContext } from "../contexts";
import { useDispatch } from "react-redux";
import { fetchOrganisations } from "../redux/features/organisationsSlice";
import { fetchFacilities } from "../redux/features/facilitiesSlice";

export function SetIsUserLoggedInProvider({ children }) {
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(() =>
    Boolean(localStorage.getItem("token"))
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(fetchOrganisations());
      dispatch(fetchFacilities());
    }
  }, [isUserLoggedIn, dispatch]);

  return (
    <SetIsUserLoggedInContext.Provider value={setIsUserLoggedIn}>
      {children}
    </SetIsUserLoggedInContext.Provider>
  );
}
