import React from "react";
import { Space, Typography, Button, Divider, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

const CustomPageHeader = ({
  subTitle,
  title,
  backIcon = <LeftOutlined />, // Default back icon
  onBack,
  extra,
  className,
  key,
  divider,
  children,
  customStyles,
  WOSelectCount,
  clearSelection,
  handleClearFilters
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  const { Text } = Typography;

  return (
    <div className={className + " custom-header-top"}>
      <Row
        align="middle"
        justify="space-between"
        className={customStyles ? "page-header-listing" : ""}
      >
        <Col>
          <Space size="small" align="start">
            <div className="customheader-left-section">
              {/* Back Button */}
              {backIcon && onBack && (
                <Button icon={backIcon} onClick={onBack} type="text" />
              )}
              {/* Title and Subtitle */}
              <Space direction="vertical" size={0}>
                <Typography.Title level={4}>
                  {title}
                  {WOSelectCount > 0 && (
                    <span>
                      <Text className="select-count">
                        {" "}
                        {WOSelectCount} Selected
                      </Text>
                      <Text
                        underline={true}
                        onClick={() => clearSelection([])}
                        className="clr-btn clear-selection-btn"
                      >
                        Clear Selection
                      </Text>
                    </span>
                  )}
                </Typography.Title>
                {subTitle && (
                  <Typography.Text type="secondary">{subTitle}</Typography.Text>
                )}
              </Space>
            </div>
          </Space>
        </Col>

        {/* Extra Content */}
        {extra && (
          <Col>
            <div className="extra-content">{extra}</div>
            {handleClearFilters && (
              <div className="clr-filter-container">
                <button
                  onClick={handleClearFilters}
                  className="clr-btn clr-filter-btn"
                >
                  Clear Search Filters
                </button>
              </div>
            )}
          </Col>
        )}
      </Row>

      {/* Divider */}
      {divider && <Divider style={{ margin: 0 }} />}

      {/* Children Content */}
      <div style={{ marginTop: 16 }}>{children}</div>
    </div>
  );
};

export default CustomPageHeader;
