import React, { useState } from "react";
import { Input, Button } from "antd";
import CustomModal from "./CustomModal";
import apiCall from "../../services/apiCall";
import ModalWorkorderCard from "./ModalWorkorderCard";
import toastNotification from "../../helpers/ToastNotification";
import { LoadingOutlined } from "@ant-design/icons";

const AddWorkorderModal = ({
  visible,
  onCancel,
  selectedRowKeys,
  clearSelection,
}) => {
  const [workorder, setWorkorder] = useState("");
  const [workordersDetails, setWorkordersDetails] = useState({});
  const [testType, setTestType] = useState("TTC");
  const [loading, setLoading] = useState({
    fetchingWO: false,
    addingWO: false,
  });
  // To check if operation is performed
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    if (/^\d*$/.test(e.target.value)) {
      setWorkorder(e.target.value)
    }
  };

  const handleGetWorkorderDetails = async () => {
    setLoading({ fetchingWO: true, addingWO: false });

    try {
      const data = await apiCall.getWorkorderDetails(workorder);
      if(data.status !== 200) {
        toastNotification("warning", data.data);
        return;
      }
      setWorkordersDetails(data?.data);
    } catch (error) {
      toastNotification("warning", "Something went wrong");
    } finally {
      setLoading({ fetchingWO: false, addingWO: false });
    }
  };

  const handleAddWorkorder = async () => {
    try {
      setLoading({ fetchingWO: false, addingWO: true });

      const proctors = selectedRowKeys.map((id) => ({
        id,
      }));

      const payLoad = {
        proctors,
        newWorkOrder: {
          workOrderNo: workordersDetails?.id,
          testType,
        },
      };

      const data = await apiCall.addBulkWorkorders(payLoad);
      if (data?.status === 200) {
        toastNotification("success", data.data);
        onCancel("add", true);
        setSuccess(true);
      } else {
        toastNotification("warning", data.data);
        setSuccess(false);
      }
    } catch (error) {
      toastNotification("warning", "Something went wrong");
    } finally {
      setLoading({ fetchingWO: false, addingWO: false });
    }
  };

  return (
    <CustomModal
      visible={visible}
      onCancel={() => onCancel("add", success)}
      title="Add Workorder"
    >
      <div className="custom-rollover-modal">
        <p>
          Enter the workorder number you want to add in all the selected proctor
          accounts
        </p>
        <div className="wo-details-group">
          <Input
            placeholder="Enter Workorder"
            value={workorder}
            onChange={handleInputChange}
            className="rollover-add-workorder-input"
            maxLength={10}
          />
          <Button
            onClick={handleGetWorkorderDetails}
            disabled={!workorder}
            type="primary"
            icon={
              loading.fetchingWO && (
                <LoadingOutlined className="add-wo-loader" />
              )
            }
            className={
              (workorder ? "add-btn-active" : "add-btn-inactive") +
              " rollover-add-workorder-btn"
            }
          >
            Add
          </Button>
        </div>
        {Object.keys(workordersDetails).length ? (
          <>
            <ModalWorkorderCard
              data={workordersDetails}
              cardStyles={{
                borderRadius: "16px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              value={testType}
              onSelect={(typeId) => setTestType(typeId)}
              removeWorkOrder={() => setWorkordersDetails({})}
            />
            <Button
              onClick={handleAddWorkorder}
              className="add-btn-active rollover-save-workorder-btn"
              disabled={!workorder}
              icon={
                loading.addingWO && (
                  <LoadingOutlined className="add-wo-loader" />
                )
              }
            >
              Save
            </Button>
          </>
        ) : null}
      </div>
    </CustomModal>
  );
};

export default AddWorkorderModal;
