import React from "react";
import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/Login/ForgotPassword";
import ClientActivated from "../pages/Login/ClientActivated";
import ResetLink from "../pages/Login/ResetLink";
import ResetPassword from "../pages/Login/ResetPassword";
import CreatePassword from "../pages/Login/CreatePassword";
import ExamineticsAdmin from "../pages/Dashboard/ExamineticsAdmin";
import ClientProctors from "../pages/Dashboard/ClientProctors";
import ExamineticsAdminForm from "../pages/Dashboard/ExamineticsAdminForm";
import ClientProctorForm from "../pages/Dashboard/ClientProctorForm";

export const RouteConstants = {
  Others: {
    Login: {
      component: <Login />,
      path: "/login",
    },
    ForgotPassword: {
      component: <ForgotPassword />,
      path: "/forgot-password",
    },
    ResetLink: {
      component: <ResetLink />,
      path: "/email-confirmation",
    },
    CreatePassword: {
      component: <CreatePassword />,
      path: "/create-Password/:token",
    },
    ResetPassword: {
      component: <ResetPassword />,
      path: "/reset-password/:token",
    },
    ClientActivated: {
      component: <ClientActivated />,
      path: "/client-activated",
    },
  },
  HomePage: {
    ExamineticsAdmin: {
      component: <ExamineticsAdmin />,
      path: "/examinetics-admin",
    },
    ClientProctors: {
      component: <ClientProctors />,
      path: "/client-proctor",
    },
    ExamineticsAdminForm: {
      component: <ExamineticsAdminForm />,
      path: "/examinetics-admin-form/:userId?",
    },
    ClientProctorForm: {
      component: <ClientProctorForm />,
      path: "/client-proctor-form/:userId?",
    },
  },
};

export const SourceConstants = {
  WRE: "WRE",
  SALUX_VAULT: "SALUX_VAULT",
};

export const UserStatusConstants = {
  ACTIVE: "Active",
  PENDING: "Pending",
  INACTIVE: "InActive",
  ALL_USERS: "",
};

export const MESSAGES = Object.freeze({
  ASSIGN_WORK_ORDER:
    "Please assign at least one work order to create the proctor.",
  SAME_ORGANIZATION:
    "A proctor can only belong to one organization. Please make sure all assigned work orders are from the same organization.",
  REMOVE_WO: "Are you sure you want to remove this workorder?",
  REMOVE_LAST_WO:
    "You are about to remove the last work order assigned to this proctor. Please ensure to add a new work order to maintain compliance.",
    REPLACE_SAME_WO: "Target workorder must be different from the selected one."
});
