import React from "react";

export function useDebounceFn(fn, timeInMs = 300) {
  const timeoutId = React.useRef(null);

  const cancelTimeout = React.useCallback(() => {
    if (timeoutId.current !== null) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  }, []);

  const fnDecorated = React.useCallback(
    (...params) => {
      cancelTimeout();
      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        fn(...params);
      }, timeInMs);
    },
    [cancelTimeout, fn, timeInMs]
  );

  return [fnDecorated, cancelTimeout];
}
