import React from "react";
// import "../../styles/login.css";
import { ReactComponent as Logo } from "../../assets/logo-original.svg";
import { ReactComponent as CheckIcon } from "../../assets/icon_check.svg";
import { Form, Input, Button, Card, Typography, Row, Col } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Copyright from "../../components/Copyright";

const ResetLink = () => {
    const location = useLocation();

    const confirmEmail = location.state ? location.state.confirmEmail : "";

    const navigate = useNavigate();

    return (
        <div className="login-container">
            <Row align="middle" style={{ width: "100%" }}>
                <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 18, offset: 3 }}
                    md={{ span: 16, offset: 4 }}
                    lg={{ span: 14, offset: 5 }}
                    xl={{ span: 10, offset: 7 }}
                    className="box-modal"
                >
                    <Row justify="center" align="top">
                        <div className="logo-container">
                            <Logo />
                        </div>
                    </Row>
                    <Card className="login-box">
                        <Form
                            requiredMark={false}
                            name="normal_login"
                            layout="vertical"
                            className="login-form"
                            initialValues={{
                                remember: true
                            }}
                        >
                            <Typography className="login-title">
                                {" "}
                                Check Email{" "}
                            </Typography>
                            <div className="login-reset-container">
                                <CheckIcon />
                                <Typography className="login-reset-text">
                                    Password reset link has been sent.
                                </Typography>
                            </div>
                            <Typography className="login-description">
                                You will receive a password reset link on your
                                email address which is valid for next 24 hours.
                                Check your spam folder too.
                            </Typography>
                            <Form.Item
                                name="email"
                                label="Email"
                                initialValue={confirmEmail}
                            >
                                <Input disabled={true} aria-label="Email" />
                            </Form.Item>

                            <Form.Item>
                                <div className="login-button-container">
                                    <Button
                                        type="primary"
                                        shape="round"
                                        htmlType="submit"
                                        onClick={() => {
                                            navigate("/login")
                                        }}
                                    >
                                        Login
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                    <Row justify="center" align="bottom">
                        <Copyright />
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
export default ResetLink;
