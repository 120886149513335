import React, { useState } from "react";
import { Input, Button, Select, Typography } from "antd";
import CustomModal from "./CustomModal";
import apiCall from "../../services/apiCall";
import ModalWorkorderCard from "./ModalWorkorderCard";
import { SwapOutlined } from "@ant-design/icons";
import toastNotification from "../../helpers/ToastNotification";
import { LoadingOutlined } from "@ant-design/icons";
import { MESSAGES } from "../../helpers/Constants";

const ReplaceWorkorderModal = ({
  visible,
  onCancel,
  clientList,
  selectedRowKeys,
  clearSelection,
}) => {
  const [replaceWithWO, setReplaceWithWO] = useState("");
  const [replaceFromWO, setReplaceFromWO] = useState("");
  const [workordersDetails, setWorkordersDetails] = useState({});
  const [testType, setTestType] = useState("TTC");
  const [loading, setLoading] = useState({
    loadFetchWODetails: false,
    loadReplaceWO: false,
    success: false,
  });

  const handleGetWorkorderDetails = async (orderNo) => {
    setLoading(prev => ({ ...prev, loadFetchWODetails: true}));
    try {
      const data = await apiCall.getWorkorderDetails(orderNo);
      if (data?.status === 200) {
        setWorkordersDetails(data?.data);
        setReplaceFromWO(orderNo);
      }
    } catch (error) {
      toastNotification("warning", "Something went wrong");
    } finally {
      setLoading(prev => ({ ...prev, loadFetchWODetails: false}));
    }
  };

  const workOrderOptions = Array.from(
    new Set(
      clientList
        ?.filter((clientObj) => selectedRowKeys?.includes(clientObj._id))
        .flatMap((clientObj) =>
          clientObj.workOrders.map((wo) => wo.workOrderNo)
        )
    )
  ).map((workOrderNo) => ({
    value: workOrderNo.toString(),
    label: workOrderNo.toString(),
  }));

  const handleReplaceWorkorder = async () => {
    setLoading(prev => ({ ...prev, loadReplaceWO: true}));


    if(replaceFromWO === replaceWithWO) {
      toastNotification("warning", MESSAGES.REPLACE_SAME_WO);
      setLoading(prev => ({ ...prev, loadReplaceWO: false, success: false}));
      return;
    }

    try {
      const proctors = selectedRowKeys.map((id) => ({
        id,
      }));

      const payLoad = {
        proctors,
        replaceFromWO: {
          workOrderNo: parseInt(replaceFromWO),
          testType,
        },
        replaceToWO: {
          workOrderNo: parseInt(replaceWithWO),
          testType,
        },
      };

      const data = await apiCall.replaceBulkWorkorders(payLoad);
      if (data?.status === 200) {
        toastNotification("success", data.data.message);
        setLoading(prev => ({ ...prev, loadReplaceWO: false, success: true}));
      } else {
        toastNotification("warning", data.data);
        setLoading(prev => ({ ...prev, loadReplaceWO: false, success: false}));
      }
    } catch (error) {
        toastNotification("warning", "Something went wrong");
    }
  };
  const { Text } = Typography;

  const handleChange = (e) => {
    if (/^\d*$/.test(e.target.value)) {
      setReplaceWithWO(e.target.value)
    }
  };

  return (
    <CustomModal
      visible={visible}
      onCancel={() => onCancel("replace", loading.success)}
      title="Replace Workorder"
    >
      <div className="replace-wo-container">
        <p className="sub-heading">
          Select the workorder you want to replace and enter the new workorder
          in the text field
        </p>
        <div className="replace-wo-input-row">
          <div className="replace-wo-input-left">
            <div className="replace-wo-input-group">
              <Text className="replace-wo-label">Selected Workorders</Text>
              <Select
                placeholder="Select One"
                onChange={handleGetWorkorderDetails}
                className="replace-wo-input-select"
                options={workOrderOptions}
              />
            </div>

            <div className="swaped-icon-container">
              <SwapOutlined className="swaped-icon" />
            </div>

            <div className="replace-wo-input-group">
              <Text className="replace-wo-label">Replace with</Text>
              <Input
                placeholder="Enter Workorder"
                onChange={handleChange}
                className="replace-wo-input-text"
                value={replaceWithWO}
                maxLength={10}
              />
            </div>
          </div>

          <Button
            onClick={handleReplaceWorkorder}
            disabled={(!replaceFromWO.length || !replaceWithWO.length) || loading.success}
            type="primary"
            className="replace-btn"
            icon={loading.loadReplaceWO && <LoadingOutlined />}
          >
            Replace
          </Button>
        </div>
        {!loading.loadFetchWODetails ? (
          Object.keys(workordersDetails).length > 0 && (
            <ModalWorkorderCard
              data={workordersDetails}
              cardStyles={{
                borderRadius: "16px",
                minWidth: "92%",
              }}
              isReplaceWoModal={true}
              success={loading.success}
              value={testType}
              onSelect={(typeId) => setTestType(typeId)}
              replacedWith={replaceWithWO}
            />
          )
        ) : (
          <Text><LoadingOutlined /></Text>
        )}
      </div>
    </CustomModal>
  );
};

export default ReplaceWorkorderModal;
